* {
    margin: 0;
    padding: 0;
}

/* body {
    background-color: #fff;
    font-size: 16px;
    max-width: 1512px;
    margin: 0 auto;
} */

input {
    border: 0 none;
    outline-style: none;
}
ul, ol, li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

p {
    margin-top: 10px;
    font-size: 18px;
    text-align: justify;
    line-height: 1.5;
}

.bags-body-bio {
    align-items: center;
    justify-content: center;
    /* width: 1512px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 50px; */
}

.product-body, .product-area {
    border: none;
}


.img-description {
    margin: auto;
    width: 99%;
    /* max-height: 480px; */
    border-radius: 6px;
    overflow: hidden;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    margin-bottom: 20px;
}

.bags-img-area {
    width: 100%;
    /* max-height: 400px; */
    border-radius: 6px;
    overflow: hidden;
    /* border: 1px solid #000; */
    position:relative;
}

.bags-img-area img {
    width: 100%;
    height: auto;
}
.bags-img-bottom {
    /* width: 96%; */
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.bags-contents {
    /* width: 750px; */
    width: 1200px;
    display: flex;
    flex-direction: column;
    /* margin-top: 10px; */
}

.bags-contents h3{
    color: rgb(0, 0, 0);
    font-size: 24px;
}

.cap-contents p {
    font-size: 18px;
}

.product-body {
    align-items: center;
    justify-content: center;
    display: flex;
}

.product-area {
    /* display: flex; */
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(310px, 1fr)); */
    grid-template-columns: repeat(4, 1fr);
    /* border: 1px solid #000; */
    /* grid-gap: 15px; */
    flex-wrap: wrap; 
    float: left;
    align-items: center;
    width: 1200px;
    margin:auto;
    margin-bottom: 30px;
}

.items {
    /* width: 310px; */
    width: calc(1200px/4);
    height: auto;
    /* box-sizing: border-box; */
    /* border-right: 1px solid #000; */
    /* border-bottom: 1px solid #000; */
    /* outline: 1px solid #000; */
    transition: all 0.2s linear;
    padding: 20px;
}

.img-box {
    /* width: calc(100% - 30px); */
    width: auto;
    height: 100%;
    max-height: 200px;
}

.item-img {
    /* width: calc(100% - 30px); */
    width: auto;
    height: 100%;
    max-height: 200px;
}

.item-img:hover { 
    transform: translateY(-2px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.1);
}

.item-name {
    width: 100%;
    height: 21px;
    color: #615f5fe4;
    line-height: 21px;
    margin-bottom: 8px;
}

.item-desc {
    width: 100%;
    height: 20px;
    color: #000;
    line-height: 20px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    text-align: center; /* Ensures the text is aligned to the left */
    white-space: normal; /* Allows the text to wrap */
    word-wrap: break-word; /* Breaks long words to fit the container */
    height: auto; /* Allows dynamic height based on content */
}

/* Ensure that the first item in each row has a left border and the last item has a right border if they are not already handled by the :first-child or :last-child */
.product-area > :nth-child(4n+1) {
    border-left: 0px solid #000;
}

.product-area > :nth-child(4n) {
    border-right: 0px solid #000;
}

.product-area > :nth-child(9),
.product-area > :nth-child(10),
.product-area > :nth-child(11),
.product-area > :nth-child(12){
    border-bottom: 1px solid #000;
}

.product-area > :nth-child(1),
.product-area > :nth-child(2),
.product-area > :nth-child(3),
.product-area > :nth-child(4){
    border-top: 1px solid #000;
}

.product-area > :nth-child(1),
.product-area > :nth-child(5),
.product-area > :nth-child(9){
    border-left: 1px solid #000;
}

.product-area > :nth-child(4),
.product-area > :nth-child(8){
    border-right: 1px solid #000;
}

.product-area > :nth-child(12){
    border-right: 0px solid #000;
}

.title-area {
    width: 1200px;
    margin-bottom: 20px;
}

.title-area h3{
    color: rgba(18, 203, 18, 0.697);
    font-size: 28px;
}

.path-area {
    display: flex;
    width: 1200px;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.path-area a {
    font-size: 16px;
}

.path-area span {
    font-size: 16px;
    font-weight: bold;
}
