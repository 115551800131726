.slick-slider .slick-arrow {
  background: #a19d9d; /* Initially lighter background */
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(243, 239, 239, 0.2);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease; /* Transition for smooth color change */
  z-index: 10;
}

.slick-slider .slick-arrow:hover {
  background: #6e6767; /* Darker shade when hovered */
}


.slick-prev, .slick-next {
  position: absolute;
  top: 50%; 
  transform: translateY(-50%);
}

.slick-prev {
  left: 10px;  
}

.slick-next {
  right: 10px;  
}


  /* Arrow size */
  /* Arrow color */
  .slick-prev:before, .slick-next:before {
    font-size: 20px; /* Icon size */
    color: #333; /* Icon color */
    content: ''; /* Use your own icons */
    display: inline-block;
    background-size: cover;
    width: 100%;
    height: 100%;
}



  .slick-slide img {
    width: auto; 
    height: 80px; 
  }
   
  .thumbnail-slider .slick-track {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns thumbnails to the start */
}

.thumbnail-slider .slick-slide {
    width: 80px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px !important; /* Adds some space between the thumbnails */
    flex: 0 0 auto; /* Prevents the thumbnails from stretching */
}

.thumbnail-slider .slick-list {
    overflow: hidden; /* Prevents scrolling */
    /* padding: 0 -5px; */
}

  .big-img .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 350px; 
    height: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }

  .big-img .slick-slide img {
    /* width: 100%;  */
    width: auto;
    height: auto; /* Adjusts height to maintain aspect ratio */
    max-width: 450px;
    max-height: 350px;
    display: block; /* Ensures the image is not inline */
    margin: auto; /* Centers the image horizontally */
  }



  .thumbnail-slider .slick-slide img {
    width: auto;
    height: 70px; /* Adjust this value to make the thumbnails smaller */
    cursor: pointer;
  }
  
  