* {
    margin: 0;
    padding: 0;
}

/* body {
    background-color: #fff;
    font-size: 16px;
    width: 1512px;
    margin: 0 auto;
} */

input {
    border: 0 none;
    outline-style: none;
}
ul, ol, li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

p {
  margin-top: 10px;
  font-size: 18px;
  text-align: justify;
  line-height: 1.5;
}

.aboutus-body {
    align-items: center;
    justify-content: center;
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.aboutus-img-area {
    margin: auto;
    width: 98%;
    max-height: 480px;
    border-radius: 6px;
    overflow: hidden;
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children along the main axis (vertical) */
    align-items: center; /* Center children along the cross axis (horizontal) */
}

.aboutus-img-area img {
    width: 100%;
    height: auto;
}

.aboutus-contents {
    /* width: 1200px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.aboutus-contents h3{
    color: rgba(18, 203, 18, 0.697);
    font-size: 24px;
}

/* .aboutus-contents p {
    margin-top: 10px;
    font-size: 18px;
    text-align: justify;
    line-height: 1.5;
} */

.timeline {
    position: relative;
    padding: 25px 0; /* Increase padding to provide space for dates */
    white-space: nowrap; /* Ensure the timeline doesn't wrap */
    overflow-x: auto; /* Allows horizontal scrolling if the timeline is too wide */
    display: flex; /* Display events in a row */
    /* align-items: center;  */
  }
  
  .event {
    position: relative;
    margin: 0 20px; /* Adjust the space between events */
    display: flex; /* Align date and description */
    flex-direction: column; /* Stack date and description */
    align-items: center; /* Align items to the center horizontally */
    padding-top: 5px; /* Maintain equal padding for visual balance */
  }

  .event:nth-of-type(1) {
    margin-left: 80px; /* Adjust this value to create more space */
  }
  
  .event:nth-of-type(2) {
    margin-left: 100px; /* Adjust the space after the second event */
  }
  
  .event:nth-of-type(3) {
    margin-left: 120px; /* Adjust the space after the third event */
  }
  
  .event:nth-of-type(4) {
    margin-left: 160px; /* Adjust the space after the fourth event */
  }
  
  
  .event-date {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .event-description {
    width: 150px;
    /* margin-top: 20px; */
    white-space: normal;
    font-size: 17px;
    background: #0099CC;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    margin-top: 30px; /* Space between line and the description */
  }
  
/* Styling for the circles and lines connecting events */
.event:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%; /* Adjust this value to center the circle along the event */
    transform: translate(-50%, -50%); /* Center the circle */
    background: #fff;
    border: 3px solid #0099CC;
    border-radius: 50%;
    height: 20px; /* Adjust the size of the circle */
    width: 20px; /* Adjust the size of the circle */
    z-index: 1;
  }
  
  
  /* Style for the horizontal dashed line */
  .timeline:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    /* border-top: 5px solid #1391e4; */
    border-top: 5px solid #0099CC;
    z-index: 0;
  }
  