* {
    margin: 0;
    padding: 0;
}

/* body {
    background-color: #fff;
    font-size: 16px;
    width: 1200px;
    margin: 0 auto;
} */

input {
    border: 0 none;
    outline-style: none;
}
ul, ol, li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

p {
    margin-top: 10px;
    font-size: 18px;
    text-align: justify;
    line-height: 1.5;
  }

.capabilities-body {
    align-items: center;
    justify-content: center;
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.cap-img-area {
    margin: auto;
    width: 98%;
    height: 100;
    max-height: 480px;
    border-radius: 6px;
    overflow: hidden;
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children along the main axis (vertical) */
    align-items: center; /* Center children along the cross axis (horizontal) */
}

.cap-img-area img {
    width: 81%;
    height: 470px;
}

.contents-pics {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cap-contents {
    /* width: 1200px; */
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.pics-area {
    width: 40%;
}

.pics-area img {
    margin-top: 20px;
    width: 100%;
    height: 280px;
    object-fit: cover;
}

.cap-contents h3{
    color: rgba(18, 203, 18, 0.697);
    font-size: 24px;
}

/* .cap-contents p {
    font-size: 18px;
} */

.cap-contents ul {
    list-style-type: none;
    padding-left: 0px; /* Add some padding to align the bullets properly */
    margin-top: 10px;
}

.cap-contents li {
    position: relative; /* Position relative to allow absolute positioning within */
    padding-left: 25px; /* Add padding to leave space for the custom bullet */
    margin-bottom: 5px;
    font-size: 18px;
    text-align: justify;
}

.cap-contents li::before {
    content: ''; /* This is necessary for the ::before element to appear */
    display: block; /* Make it a block to apply width and height */
    position: absolute; /* Absolute positioning relative to the li */
    left: 0; /* Align to the left of the li */
    top: 50%; /* Center it vertically */
    transform: translateY(-50%); /* Offset by half its height to truly center */
    width: 5px; /* The width of the custom bullet */
    height: 5px; /* The height of the custom bullet */
    background-color: #153e35; /* The color of the custom bullet */
    border-radius: 50%; /* Make it round */
}



